/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: fontawesome-webfont;

  src: url("../assets/fonts/fontawesome-webfont.ttf");
}
